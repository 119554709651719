<template>
  <div class="row h-100 login-wrap">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"></loading>
    <div class="col-12 left-login">
      <div class="auth-content col-md-11">
        <div class="card-body">
          <!-- Form message -->
          <div class="text-center label subtitle-content" v-if="!sended">
            {{ $t('request-access.title') }}<br/>
            {{ $t('request-access.has_an_account') }}
            <router-link :to="{name: 'Login'}" target='_blank'>
              {{ $t('login.click-here') }}!
            </router-link>
          </div>
          <!-- Form Success message -->
          <div class="text-center label subtitle-content" v-if="sended && successSend">
            <h5 class="mb-4">Solicitação de acesso recebida!</h5>
            Nossa equipe vai avaliar sua requisição e você será avisado por e-mail quando seu registro for aprovado<br/><br/>
            Você já recebeu o e-mail de aprovação? Então <router-link :to="{name: 'Login'}" target='_blank'>Clique aqui</router-link> para fazer o login na sua conta.
          </div>

          <!-- Form Error message -->
          <div class="text-center label subtitle-content" v-if="sended && !successSend">
            <h5 class="mb-4">Ops! Algo deu errado...</h5>
            Sua solicitação de acesso não foi concluída. Se você já fez essa requisição antes, não é necessário fazer novamente.<br/><br/>
            Agora basta aguardar nossa equipe avaliar a liberação do seu acesso.<br/><br/>
            Você vai receber um e-mail quando sua solicitação for aceita.<br/><br/>
            Você já recebeu o e-mail de aprovação? Então <router-link :to="{name: 'Login'}" target='_blank'>Clique aqui</router-link> para fazer o login na sua conta.
          </div>
          <!-- Form-->
          <form v-if="!sended" method="post" data-vv-scope="frmRequestAccess">
            <div class="row">

              <!-- Identificador de origem da solicitação -->
              <input type="hidden" id="request-origin" name="request-origin" :value="requestAccess.requestOrigin">

              <div class="col-md-6 mb-4">
                <custom-input
                  v-model="requestAccess.companyName"
                  name="requestAccess.companyName"
                  :label="this.$i18n.t('request-access.companyName')"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :maxLength="70"
                  v-validate="{ required: true }"
                  :error="errors.first('requestAccess.companyName')"
                >
                </custom-input>
              </div>
              <div class="col-md-6 mb-4">
                <custom-input
                  v-model="requestAccess.cnpj"
                  name="requestAccess.cnpj"
                  :label="this.$i18n.t('request-access.cnpj')"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true, validCNPJ: true }"
                  :v-mask="{ mask: '[99.999.999/9999-99]', clearIncomplete: true, showMaskOnFocus: false, showMaskOnHover: false}"
                  :error="(submitted) ? errors.first('requestAccess.cnpj') : ''"
                >
                </custom-input>
              </div>
              <div class="col-md-6 mb-4">
                <custom-input
                  v-model="requestAccess.fone"
                  name="requestAccess.fone"
                  :label="this.$i18n.t('request-access.fone')"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :v-mask="{ mask: ['[(99) 9999-9999]', '[(99) 99999-9999]'], clearIncomplete: true}"
                  v-validate="{ required: true }"
                  :error="(submitted) ? errors.first('requestAccess.fone') : ''"
                >
                </custom-input>
              </div>
              <div class="col-md-6 mb-4">
                <custom-input
                  v-model="requestAccess.zipcode"
                  :label="this.$i18n.t('request-access.zipcode')"
                  name="requestAccess.zipcode"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :v-mask="{ mask: '[99999-999]', clearIncomplete: true}"
                  :autoUnmask="true"
                  :error="(submitted) ? errors.first('requestAccess.zipcode') : ''"
                  @input="onCepInput"
                >
                </custom-input>
              </div>
              <div class="col-md-6 mb-4">
                <v-select   class="md-form md-form-control"
                            label="nome"
                            v-model="requestAccess.state"
                            name="requestAccess.state"
                            :placeholder="this.$i18n.t('request-access.state')"
                            :searchable="true"
                            :clearable="false"
                            :options="stateList"
                            @input="onSelectState"
                            :filter-by="selectFilter"
                            v-validate="{ required: true }"
                >
                  <template slot="no-options">
                    {{ $t('empty') }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="(submitted) ? errors.first('requestAccess.state'): ''" class="royalc-error-field">{{ errors.first('requestAccess.state') }}</small>
                </transition>
              </div>
              <div class="col-md-6 mb-4">
                <v-select   class="md-form md-form-control"
                            label="nome"
                            name="requestAccess.city"
                            v-model="requestAccess.city"
                            :placeholder="this.$i18n.t('request-access.city')"
                            :searchable="true"
                            :clearable="false"
                            :options="cityList"
                            :filter-by="selectFilter"
                            v-validate="{ required: true }"
                >
                  <template slot="no-options">
                    {{ $t('empty') }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="(submitted) ? errors.first('requestAccess.city'): ''" class="royalc-error-field">{{ errors.first('requestAccess.city') }}</small>
                </transition>
              </div>

              <div class="col-md-6 mb-4">
                <custom-input
                  v-model="requestAccess.firstName"
                  name="requestAccess.firstName"
                  :label="this.$i18n.t('request-access.firstName')"
                  type="text"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  :maxLength="70"
                  v-validate="{ required: true }"
                  :error="(submitted) ? errors.first('requestAccess.firstName') : ''"
                >
                </custom-input>
              </div>

              <div class="col-md-6 mb-4">
                <custom-input
                  v-model="requestAccess.lastName"
                  name="requestAccess.lastName"
                  :label="this.$i18n.t('request-access.lastName')"
                  type="text"
                  :maxLength="70"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :error="(submitted) ? errors.first('requestAccess.lastName') : ''"
                >
                </custom-input>
              </div>

              <div class="col-md-12 mb-4">
                <custom-input
                  v-model="requestAccess.email"
                  name="requestAccess.email"
                  :label="this.$i18n.t('request-access.email')"
                  type="text"
                  :maxLength="70"
                  rootClassName="md-form mb-0"
                  inputClassName="md-form-control"
                  v-validate="{ required: true, email: true }"
                  :error="(submitted) ? errors.first('requestAccess.email') : ''"
                >
                </custom-input>
              </div>
            </div>

            <div class="check mb-6 ">
              <label class="checkbox">
                <input  v-validate="{ required: true }" v-model="requestAccess.accept"
                  type="checkbox" id="accept" name="requestAccess.accept" value="on">
                <span>{{ $t('request-access.accept') }}
                  <router-link :to="{name: 'TermosCondicoesSPA'}" target='_blank' >
                    {{ $t('request-access.terms_conditions') }}
                  </router-link>
                </span>
              </label>
              <transition name="slide" mode="">
                <small v-if="(submitted) ? errors.first('requestAccess.accept'): ''" class="royalc-error-field">{{ errors.first('requestAccess.accept') }}</small>
              </transition>
            </div>
            <div class="mb-5 text-center">
              <reCaptchaComponent :disabled="isLoading"
                data-sitekey="6LdgBIUkAAAAADeBvN7rojE4ZvT6zWBH8Bu2vjjM"
                :data-validate="validate"
                :data-callback="callback"
                :data-btn-class="'btn btn-primary btn-padding'"
              >
                {{ $t('request-access.send') }}
              </reCaptchaComponent>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import reCaptchaComponent from '@/components/reCaptcha/reCaptchaComponent'

// Form
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// import router from '@/router'
import BaseService from '@/services/BaseService'
import AddressService from '@/services/AddressService'
import UserService from '@/services/UserService'
import CustomInput from '@/components/Forms/CustomInput.vue'
import VeeValidate from 'vee-validate'
import { locale, validCNPJ } from '@/utils/validator'

import '../login.css'
import '@/assets/css/custom.css'

VeeValidate.Validator.localize({ 'pt_BR': locale })
VeeValidate.Validator.extend('validCNPJ', validCNPJ)
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'Login',
  metaInfo: {
    titleTemplate: '%s | Login'
  },
  components: {
    CustomInput,
    vSelect,
    Loading,
    reCaptchaComponent
  },
  data () {
    return {
      sended: false,
      successSend: false,
      isLoading: false,
      BaseURL: process.env.VUE_APP_BaseURL,
      stateList: [],
      cityList: [],
      requestAccess: {
        companyName: null,
        cnpj: null,
        zipcode: null,
        fone: null,
        state: null,
        city: null,
        firstName: null,
        lastName: null,
        email: null,
        accept: false,
        requestOrigin: 1 // SPA
      },
      submitted: false,
      loading: false
    }
  },
  created () {
    let _this = this
    this.isLoading = true
    BaseService.checkAccess().then(res => {
      if (res.data.code === 201 && res.data.message === 'Sucesso') {
        // Load basilares data
        AddressService.getUFs({ country: 22, per_page: 50 }).then(res => {
          _this.stateList = res.data.data
        })
      }
    }).catch(() => {
      this.error = true
      global.instanceApp.$alertSwal.modalAlert('', '<div>Algo deu errado! Vamos verificar o que houve?</div>', 'warning').then((result) => {
        global.instanceApp.$router.push({ name: 'CheckNetwork' })
      })
    }).finally(() => {
      this.isLoading = false
    })
  },
  methods: {
    validate () {
      let _this = this
      let scopeName = 'frmRequestAccess'

      _this.submitted = true
      _this.isLoading = true

      return new Promise((resolve, reject) => {
        _this.$validator.validateAll(scopeName).then((res) => {
          // Se inválido remove loading
          if (!res) {
            _this.isLoading = false
          }
          resolve(res)
        })
      })
    },
    callback (token) {
      let _this = this
      // google recaptcha token , then you can pass to backend with your form data .
      if (token) {
        // <!-- Event snippet for Inscrição no eRoyal Royal Cargo conversion page -->
        let requestAccess = _this.requestAccess

        let requestAccessData = {
          nome: requestAccess.firstName,
          sobrenome: requestAccess.lastName,
          email: requestAccess.email,
          cep: requestAccess.zipcode,
          nomeFantasia: requestAccess.companyName,
          cnpj: requestAccess.cnpj,
          fone: requestAccess.fone,
          aceitoTermosCondicoes: requestAccess.accept,
          idpais: 22,
          idUnidadeFederativa: requestAccess.state.id,
          idMunicipio: requestAccess.city.id,
          origem: requestAccess.requestOrigin
        }
        _this.isLoading = true
        UserService.newAccessRequest(requestAccessData).then(function (res) {
          _this.sended = true
          if (res === true) {
            _this.successSend = true
          } else {
            _this.successSend = false
          }
        }).finally(() => {
          _this.isLoading = false
        })
      } else {
        // if you use data-size show reCAPTCHA , maybe you will get empty token.
        _this.isLoading = false
      }
    },
    onSelectState (val) {
      let _this = this
      _this.isLoading = true
      _this.requestAccess.city = null
      AddressService.getCities({ state: val.id, per_page: 500 }).then(res => {
        _this.cityList = res.data.data
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    selectFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    onCepInput (value) {
      let _this = this
      value = value.replace(/[^0-9]+/g, '')

      if (value && value.length >= 8) {
        _this.isLoading = true
        BaseService.getCEP(value).then(function (res) {
          let cepInfo = res.data.data
          if (cepInfo) {
            _this.isLoading = true
            let state = _this.stateList.find(e => e.sigla === (cepInfo.uf))

            if (state) {
              _this.requestAccess.state = state
              AddressService.getCities({ state: state.id, per_page: 500 }).then(res => {
                _this.cityList = res.data.data

                if (cepInfo.localidade) {
                  let city = _this.cityList.find(e => {
                    let localidade = cepInfo.localidade.toLowerCase()
                    localidade = localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                    let nome = e.nome.toLowerCase()
                    nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                    return (nome.indexOf(localidade) > -1)
                  })

                  if (city) {
                    _this.requestAccess.city = city
                  }
                }
                _this.isLoading = false
              }).catch(() => {
                _this.isLoading = false
              })
            }
          } else {
            _this.requestAccess.state = null
            _this.requestAccess.city = null
            _this.cityList = []
          }
          _this.isLoading = false
        }).catch(() => {
          _this.isLoading = false
        })
      }
    }
  }
}
</script>

<style scoped>
  .fast-access {
    position: absolute;
    right: 0;
    top: 10px;
    display: inline-block;
  }

  .fast-access a {
    color: #8c6d38 !important;
    padding: 10px;
    font-weight: normal;
  }

  .login-wrap .left-login form span {
    font-size: 0.95em;
  }

  .login-wrap .left-login .subtitle-content {
   max-width: 450px;
   margin: auto;
  }

  .content-welcome a {
    background-color: inherit;
    color: #d1b178 !important;
    padding-left: 0;
  }

  .content-welcome a:hover {
    color: #8c6d38 !important;
  }

  .auth-content a {
    color: #d1b178 !important;
  }

  .auth-content a:hover {
    color: #8c6d38 !important;
  }
</style>
